import { BookNewAppointment } from '@portal/components/new_appointment';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { partition } from 'lodash';

import { Text, mq } from '@clutter/clean';
import { Badge, Button, Text as BootstrapText } from '@shared/components/bootstrap';
import { CollapsibleTableCell } from '@portal/components/helpers/table';
import { appointmentURL, orderURL } from '@portal/config/routes';

import {
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  useOrdersListQuery,
  OrdersListQuery,
  OrderStatusEnum,
} from '@portal/schema';
import { ActiveOrders } from './active/orders';
import { ActiveShipments } from './active/shipments';
import { HelpfulLinks } from './active/helpful_links';

export type Order = OrdersListQuery['orders'][0];

const Title = styled(Text.Title)`
  ${mq({
    marginTop: [0, '90px'],
  })}
`;

const PastAppointmentsTitle = styled(Title)`
  margin-right: auto;
  margin-top: 60px;
  ${mq({
    marginBottom: ['8px', '16px'],
  })}
`;

const FROM_ISO_OPTIONS = { setZone: true };

export const Index: React.FC = () => {
  const { data, loading } = useOrdersListQuery({
    variables: { onlyCustomerSource: true },
  });
  const [selections, setSelections] = useState<Set<Order>>(new Set());

  const show = (order: Order) => {
    const replacements = new Set(selections);
    replacements.add(order);
    setSelections(replacements);
  };

  const hide = (order: Order) => {
    const replacements = new Set(selections);
    replacements.delete(order);
    setSelections(replacements);
  };

  const primary = (order: Order) => order.subtype === OrderSubtypeEnum.Onboarding || order.type === OrderTypeEnum.Move;

  const hasPrimaryOrder = (orders: Order[]) => {
    const [potentialPrimaryOrders, supplementaryOrders] = partition(orders, primary);

    return (
      potentialPrimaryOrders.length === 1 &&
      supplementaryOrders.every((order) => order.subtype === OrderSubtypeEnum.SupplyKit)
    );
  };

  const pastAppointmentBadgeText = (order: Order) => {
    if (order.serviceType === OrderServiceTypeEnum.DropOff) return 'DROP OFF';
    else if (order.subtype === OrderSubtypeEnum.SupplyKit) return 'SUPPLY KIT';
    else if (order.serviceType === OrderServiceTypeEnum.Shipment) return 'SHIPMENT';
    else return order.services.map(({ type }) => type).join(' + ');
  };

  if (loading || !data) {
    return null;
  }

  const customerSourceOrders = data.orders;
  let activeOrders: Order[] = [];
  const pastOrders: Order[] = [];
  customerSourceOrders.forEach((order) => {
    if (
      (order.type === OrderTypeEnum.Disposal && order.status === OrderStatusEnum.Pending) ||
      order.isActive ||
      order.allSuccessors.some((successor) => successor.isActive)
    ) {
      activeOrders.push(order);
    } else {
      pastOrders.push(order);
    }
  });
  activeOrders = activeOrders.slice().reverse();

  const activeShipmentOrders = activeOrders.filter((order) => order.serviceType === OrderServiceTypeEnum.Shipment);
  const activeNonShipmentOrders = activeOrders.filter((order) => order.serviceType !== OrderServiceTypeEnum.Shipment);
  const unscheduledAuctionWins = data.account?.unscheduledStorageTreasuresAuctionsWon ?? [];

  const hasActiveOrders = activeOrders.length > 0;
  const hasActiveShipmentOrder = activeShipmentOrders.length > 0;
  const hasActiveNonShipmentOrder = activeNonShipmentOrders.length > 0;
  const hasUnscheduledAuctionWin = unscheduledAuctionWins.length > 0;

  if (hasUnscheduledAuctionWin) {
    return <Redirect to={{ pathname: appointmentURL() }} />;
  }

  // Redirect users with only one upcoming onboarding or move onto our landing details page
  if (hasPrimaryOrder(activeOrders) && !hasUnscheduledAuctionWin) {
    const primaryOrder = activeOrders.find(primary);
    if (primaryOrder) return <Redirect to={{ pathname: orderURL(primaryOrder.id), search: window.location.search }} />;
  }

  return (
    <>
      {hasActiveNonShipmentOrder && <ActiveOrders editable orders={activeNonShipmentOrders} />}
      {hasActiveShipmentOrder && <ActiveShipments orders={activeShipmentOrders} />}
      {(hasActiveShipmentOrder || hasActiveNonShipmentOrder) && <HelpfulLinks />}
      {!hasActiveOrders && (
        <BookNewAppointment
          reonboarding={data.account.onboardable}
          requestableItemsExist={data.account.requestableItemsExist}
        />
      )}
      {pastOrders && pastOrders.length > 0 && (
        <>
          <PastAppointmentsTitle size="extraSmall">Past Appointments</PastAppointmentsTitle>
          <table className="table">
            <thead>
              <tr className="d-none d-md-table-row">
                <th className="d-none d-md-table-cell">
                  <BootstrapText wrapping="nowrap">Appointment Date</BootstrapText>
                </th>
                <th className="d-none d-md-table-cell">
                  <BootstrapText wrapping="nowrap">Address</BootstrapText>
                </th>
                <th className="d-none d-md-table-cell">
                  <BootstrapText wrapping="nowrap">Status</BootstrapText>
                </th>
                <th className="d-none d-md-table-cell" />
              </tr>
            </thead>
            <tbody>
              {pastOrders.map((order) => {
                const selected = selections.has(order);
                const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS);

                const displayedAddress = order.address;
                return (
                  <tr className="d-block d-md-table-row mb-2 mt-2 mb-md-0 mt-md-0" key={order.id}>
                    <td className="d-block d-md-table-cell">
                      <div className="float-right d-block d-md-none">
                        <Button kind="link" onClick={() => (selected ? hide : show)(order)}>
                          {selected ? 'Collapse' : 'Expand'}
                        </Button>
                      </div>
                      <Badge style="info">{pastAppointmentBadgeText(order)}</Badge>{' '}
                      <Link to={orderURL(order.id)}>{scheduled.toLocaleString(DateTime.DATE_HUGE)}</Link>
                    </td>
                    <CollapsibleTableCell selected={selected} name="Address">
                      <> {displayedAddress.street} </>
                      <br />
                      <> {displayedAddress.aptsuite} </>
                    </CollapsibleTableCell>
                    <CollapsibleTableCell selected={selected} name="Status">
                      <Badge style="light">{order.status}</Badge>
                    </CollapsibleTableCell>
                    <CollapsibleTableCell selected={selected}>
                      <Link to={orderURL(order.id)}>View</Link>
                    </CollapsibleTableCell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
