import { DateTime, Duration } from 'luxon';

import {
  Order__ContactInput,
  InventoryItemFragment,
  OrderInput,
  OrderServiceTypeEnum,
  OrderTypeEnum,
  ShipmentInput,
  Order__PickupVehicleKind,
} from '@portal/schema';
import { IOrderInventory } from '@shared/types';

import { buildPickupInventoryInput } from './build_pickup_inventory_input';
import { buildReturnInventoryInput } from './build_return_inventory_input';

export interface IBuildOrderInputProps {
  type: OrderTypeEnum;
  scheduled?: DateTime;
  duration?: Duration;
  addressID?: string;
  contact?: Order__ContactInput;
  items: InventoryItemFragment[];
  inventory: IOrderInventory;
  laborRateID?: string;
  serviceType?: OrderServiceTypeEnum;
  accountCancelIntentID?: string;
  shipments?: ShipmentInput[];
  customerShipmentPrice?: number;
  maxCustomerShipmentPrice?: number;
  perMoverHourAdjustmentAmount?: number;
  waitlistedDates?: DateTime[];
  confirmedDate?: boolean;
  pickupVehicleKind?: Order__PickupVehicleKind;
  waiveFees?: boolean;
  waiveDocuments?: boolean;
  storageTreasuresAuctionID?: string;
}

export const buildOrderInput = ({
  type,
  scheduled,
  duration,
  addressID,
  contact,
  items,
  inventory,
  serviceType,
  accountCancelIntentID,
  shipments,
  customerShipmentPrice,
  maxCustomerShipmentPrice,
  perMoverHourAdjustmentAmount,
  laborRateID,
  waitlistedDates,
  confirmedDate,
  pickupVehicleKind,
  waiveFees,
  waiveDocuments,
  storageTreasuresAuctionID,
}: IBuildOrderInputProps): OrderInput => ({
  type,
  contact,
  serviceType,
  accountCancelIntentID,
  scheduled: scheduled && scheduled.toJSON(),
  duration: duration && duration.toJSON(),
  addressID,
  shipments,
  customerShipmentPrice,
  maxCustomerShipmentPrice,
  perMoverHourAdjustmentAmount,
  laborRateID,
  waitlistedDates: waitlistedDates?.map((date) => date.toJSON()),
  confirmedDate,
  pickupVehicleKind,
  waiveFees,
  waiveDocuments,
  storageTreasuresAuctionID,
  ...buildPickupInventoryInput({ inventory }),
  ...buildReturnInventoryInput({ items }),
});
