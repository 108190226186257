import * as React from 'react';

import styled from '@emotion/styled';

import { DateTime } from 'luxon';
import { StorageTreasuresAuctionFragment } from '@portal/schema';
import { Box, Button, Text } from '@clutter/clean';
import { Base } from './base';
import { IStepProps } from '../form';

const Header = styled(Text.Title)`
  margin-bottom: 32px;
`;

const FROM_ISO_OPTIONS = { setZone: true };

export const StepAuctionSelection: React.FC<
  {
    options: StorageTreasuresAuctionFragment[];
    onSelect(selectedAuction: StorageTreasuresAuctionFragment): void;
  } & IStepProps
> = ({ options, onSelect }) => (
  <Base>
    <Header size="large">Unscheduled Auction Pickups</Header>
    <table className="table">
      <thead>
        <tr>
          <th>
            <Text>Auction Close Date</Text>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {options.map((auction) => (
          <tr key={auction.id}>
            <td>{DateTime.fromISO(auction.endTime, FROM_ISO_OPTIONS).toLocaleString(DateTime.DATE_HUGE)}</td>
            <td align="right">
              <Box.Flex flexDirection={'column'} gap="8px" textAlign={'center'}>
                <Button onClick={() => onSelect(auction)}>Schedule Pickup</Button>
                <a href={auction.storageTreasuresLink} target="_blank">
                  View on Storage Treasures
                </a>
              </Box.Flex>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Base>
);
