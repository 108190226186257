import React from 'react';
import styled from '@emotion/styled';
import { Box, ExternalContent, SpinLoader, Text } from '@clutter/clean';

import { Info as FacilityInfo } from '@portal/components/orders/facility/info';
import { FacilityFragment, useFacilityInstructionsQuery, Zendesk__ArticleName } from '@portal/schema';

import { Base } from './base';
import { IStepProps } from '../form';

const Title = styled(Text.Title)`
  margin-bottom: 16px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px;
`;

const Container = styled(Box)`
  max-width: 48rem;
`;

const Loader = () => (
  <SpinnerContainer>
    <SpinLoader />
  </SpinnerContainer>
);

const DISCLAIMER =
  'If you arrive after this window, you will be charged the reschedule fee as noted in the “Facility Policies” section.';

export const FacilityExpectations: React.FC<
  {
    facility: FacilityFragment;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ facility, onNext, onPrev }) => {
  const { data, loading } = useFacilityInstructionsQuery({
    variables: { articleName: Zendesk__ArticleName.FacilityExpectations },
  });

  const zendeskCopy = data?.zendeskCopy;

  return (
    <Base onNext={onNext} onPrev={onPrev} valid={!loading}>
      <Container>
        <Title size="large">What to expect at a warehouse pickup</Title>
        {loading ? (
          <Loader />
        ) : (
          <>
            <FacilityInfo facility={facility!} disclaimer={DISCLAIMER} />
            {zendeskCopy && <ExternalContent dangerouslySetInnerHTML={{ __html: zendeskCopy }} />}
          </>
        )}
      </Container>
    </Base>
  );
};
