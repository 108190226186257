import React, { useState } from 'react';
import { OrderSubtypeEnum, useOrdersQuery } from '@portal/schema';

import { appointmentURL, ordersURL } from '@portal/config/routes';
import { Box, Modal, Text } from '@clutter/clean';
import { ServiceCards } from '../new_appointment/service_cards';

interface IAppointmentLinkProps {
  component?: React.ElementType;
}

export const ScheduleAppointmentLink = ({ component: Component = 'a' }: IAppointmentLinkProps) => {
  const [showReonboardingModal, setShowReonboardingModal] = useState<boolean>(false);
  const { data, loading } = useOrdersQuery({
    variables: { onlyActive: true },
  });

  if (loading || !data) {
    return null;
  }

  const { orders, account } = data;
  const orderCount = orders.length;

  const unscheduledAuctionWins = data?.account.unscheduledStorageTreasuresAuctionsWon ?? [];
  const hasUnscheduledAuctionWin = unscheduledAuctionWins.length > 0;

  let link: string;
  let text: string;
  let objectName: string;
  if (hasUnscheduledAuctionWin) {
    link = appointmentURL();
    text = 'Schedule Auction Pickup';
    objectName = 'schedule_appointment';
  } else if (orderCount > 0 && orders[0].subtype === OrderSubtypeEnum.Onboarding) {
    link = ordersURL();
    text = `View Upcoming Appointment${orderCount > 1 ? 's' : ''}`;
    objectName = 'upcoming_appointment';
  } else {
    text = 'Schedule Appointment';
    objectName = 'schedule_appointment';
    if (account.onboardable) {
      link = '';
    } else {
      link = appointmentURL();
    }
  }

  const onClick: React.MouseEventHandler = (event) => {
    if (link !== '') {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setShowReonboardingModal(true);
    return false;
  };

  return (
    <>
      <Component
        href={link}
        text={text}
        objectName={objectName}
        onClick={onClick}
        className={Component === 'a' && 'btn btn-block btn-primary'}
      >
        {text}
      </Component>
      <Modal includeCloseButton isOpen={showReonboardingModal} handleModalClose={() => setShowReonboardingModal(false)}>
        <Box padding={'56px 24px 40px'} borderRadius="4px">
          <Box margin="0 0 32px 0">
            <Text.Title size="small">How can we help?</Text.Title>
          </Box>
          <ServiceCards requestableItemsExist={account.requestableItemsExist} />
        </Box>
      </Modal>
    </>
  );
};
