import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Order__ContactInput,
  FacilityFragment,
  InventoryContentDocument,
  InventoryItemFragment,
  ItemStatus,
  OrdersDocument,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  Status,
  useBuildOrderMutation,
  useChargeEarlyAccountClosureFeeMutation,
  useDisclaimersForBookingOrderQuery,
  useReturnInventoryQuery,
} from '@portal/schema';

import { Text } from '@clutter/clean';

import { Card } from '@portal/components/addresses/card';
import { PickupInventory, ReturnInventory } from '@portal/components/helpers';
import { TermCommitmentAction } from '@portal/components/orders/term_commitment/options';
import { ordersURL, orderURL } from '@portal/config/routes';
import { IAddress, IAddressWithDetails, IContact, IOrderInventory } from '@portal/types';
import { buildOrderInput } from '@portal/utils';
import { Alert, Bordered, Button } from '@shared/components/bootstrap';

import { isSelected } from '@portal/components/return_inventory';
import { Section } from '@portal/components/orders/steps/confirm/section';
import { SERVICE_TYPE_MAPPING } from '@portal/components/orders/service_type/service_type_mapping';
import { useOrderInput } from '@portal/components/orders/steps/order_context';
import { Disposal } from '@portal/components/orders/steps/confirm/disposal';
import { PAGE_SIZE } from '@portal/components/items/list/index';
import { IMPERSONATOR } from '@portal/config/impersonator';
import { User__Role } from '@admin/schema';
import { formatTime } from '@portal/utils/scheduling';
import { isAllDaySLA } from '@shared/utils';
import { Base } from './base';
import { TermCommitmentPayment } from './confirm/term_commitment_payment';
import { Disclaimers } from './disclaimers';
import { IStepProps } from '../form';
import { useItemPricing } from './item_pricing';
import { PerItemPricingConfirmFooter } from './per_item_confirm_footer';
import { IShippingOption } from './shipping/options';
import { OutboundShipping as ConfirmOutboundShipping } from './confirm/outbound_shipping';
import { InboundShipping as ConfirmInboundShipping } from './confirm/inbound_shipping';
import { IInboundShipmentInput } from './shipping/shipping';
import { LaborRateData } from './scheduled';
import { Waitlist } from './confirm/waitlist';
import { PaymentMethodSelection } from './confirm/payment_method_selection';

const DATE_OPTIONS = { year: 'numeric', month: 'long', weekday: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;

const Scheduling: React.FC<{
  scheduled: DateTime;
  duration?: Duration;
  facilityWarehouse?: FacilityFragment;
  confirmedDate: boolean;
}> = ({ scheduled, duration, facilityWarehouse, confirmedDate }) => {
  const from = scheduled;
  const till = scheduled.plus(duration!);

  return (
    <Section title="Date and Arrival Window">
      <Text.Body>
        {confirmedDate ? (
          <>
            <strong>{scheduled.toLocaleString(DATE_OPTIONS)}</strong>
            <br />
            {formatTime(from)} - {formatTime(till)}
            {!facilityWarehouse && isAllDaySLA(from.hour, till.hour) && (
              <Text.Callout>
                A more narrow arrival window will be sent to you the evening before your appointment.
              </Text.Callout>
            )}
          </>
        ) : (
          <Text.Body>
            You do not have a confirmed date. We will notify you if any waitlisted dates become available.
          </Text.Body>
        )}
      </Text.Body>
    </Section>
  );
};

const Address: React.FC<{ address: IAddress }> = ({ address }) => (
  <Section title="Address">
    <Bordered>
      <Card address={address} />
    </Bordered>
  </Section>
);

const Contact: React.FC<{ contact: IContact }> = ({ contact }) => (
  <Section title="Onsite Contact">
    <Text.Body>
      {contact.name}
      <br />
      {contact.phone}
    </Text.Body>
  </Section>
);

const ServiceType: React.FC = () => {
  const orderInput = useOrderInput();
  const { type, serviceType } = orderInput;

  const serviceTypeData = SERVICE_TYPE_MAPPING[type]?.serviceTypeOptions.find(
    (card) => card.serviceType === serviceType,
  );

  return (
    <>{!!serviceTypeData && <Section title="Appointment Type">You selected {serviceTypeData.infoHeader}</Section>}</>
  );
};

const isPickupReturn = (type: OrderTypeEnum) => type === OrderTypeEnum.PickupReturn;
const hasPickup = (type: OrderTypeEnum) => type === OrderTypeEnum.Pickup || isPickupReturn(type);
const hasReturn = (type: OrderTypeEnum) => type === OrderTypeEnum.Return || isPickupReturn(type);

const Container = styled.div`
  h2 {
    margin-top: 30px;
  }
`;

export const Confirm: React.FC<
  {
    accountCancelIntentID?: string;
    address: IAddressWithDetails;
    contact?: Order__ContactInput;
    duration?: Duration;
    inventory: IOrderInventory;
    items: InventoryItemFragment[];
    scheduled: DateTime;
    subtype?: OrderSubtypeEnum;
    termCommitmentAction?: TermCommitmentAction;
    shippingOption?: IShippingOption;
    onPrev(): void;
    inboundShipments: IInboundShipmentInput[];
    facilityWarehouse?: FacilityFragment;
    extendedServiceArea: boolean;
    laborRateData?: LaborRateData;
    confirmedDate: boolean;
    waitlistedDates: DateTime[];
    wonAuctionID?: string;
  } & IStepProps
> = ({
  accountCancelIntentID,
  address,
  contact,
  duration,
  inventory,
  items,
  scheduled,
  subtype,
  termCommitmentAction,
  shippingOption,
  onPrev,
  inboundShipments,
  facilityWarehouse,
  extendedServiceArea,
  laborRateData,
  confirmedDate,
  waitlistedDates,
  wonAuctionID,
}) => {
  const data = useItemPricing();
  const orderInput = useOrderInput();
  const { type, serviceType, pickupVehicleKind } = orderInput;
  const { data: inventoryData, loading: inventoryLoading } = useReturnInventoryQuery();
  const [orderID, setOrderID] = useState<string>();
  const [error, setError] = useState<string | undefined>();
  const [paymentSourceID, setPaymentSourceID] = useState<string>();
  const [paymentError, setPaymentError] = useState<string | undefined>();
  const [waiveFees, setWaiveFees] = useState<boolean>(false);
  const [waiveDocuments, setWaiveDocuments] = useState<boolean>(false);

  const [save, { loading: saving }] = useBuildOrderMutation({
    refetchQueries: [
      { query: OrdersDocument },
      { query: OrdersDocument, variables: { onlyActive: true } },
      {
        query: InventoryContentDocument,
        variables: { filters: { status: ItemStatus.Available, query: '' }, query: '', page: 1, per: PAGE_SIZE },
      },
    ],
  });
  const [chargeEarlyAccountClosureFee, { loading: charging }] = useChargeEarlyAccountClosureFeeMutation();

  const shipments = inboundShipments.map((shipment) => ({
    carrier: shipment.easyPostRate!.carrier,
    contents: shipment.contentDescription!,
    currency: shipment.easyPostRate!.currency!,
    easyPostRateId: shipment.easyPostRate!.id,
    easyPostShipmentId: shipment.easyPostShipmentID!,
    height: shipment.height!,
    length: shipment.length!,
    rate: shipment.easyPostRate!.rate,
    service: shipment.easyPostRate!.service,
    weight: shipment.weight!,
    width: shipment.width!,
  }));

  const input = buildOrderInput({
    type,
    addressID: address?.id,
    inventory,
    items,
    contact,
    scheduled,
    duration,
    serviceType: serviceType!,
    accountCancelIntentID: subtype === OrderSubtypeEnum.Final ? accountCancelIntentID : undefined,
    shipments,
    customerShipmentPrice: shippingOption?.price,
    maxCustomerShipmentPrice: shippingOption?.maxPrice,
    laborRateID: laborRateData?.laborRate.id,
    perMoverHourAdjustmentAmount: laborRateData?.perMoverHourAdjustmentAmount,
    confirmedDate,
    waitlistedDates,
    pickupVehicleKind: pickupVehicleKind || undefined,
    waiveFees: waiveFees,
    waiveDocuments: waiveDocuments,
    storageTreasuresAuctionID: wonAuctionID,
  });

  const { data: disclaimersData } = useDisclaimersForBookingOrderQuery({ variables: { input: input } });
  const chargeTermCommitment = termCommitmentAction === TermCommitmentAction.Pay;

  const onSave = async () => {
    setError(undefined);
    setPaymentError(undefined);

    const requiresPayment = chargeTermCommitment || (serviceType === OrderServiceTypeEnum.Disposal && !waiveFees);
    if (!paymentSourceID && requiresPayment) {
      setPaymentError('Please select a payment method');
      return;
    }

    if (termCommitmentAction === TermCommitmentAction.Pay) {
      const response = await chargeEarlyAccountClosureFee({
        variables: { sourceID: paymentSourceID!, scheduled: scheduled.toJSON() },
      });
      if (response?.data?.charge?.status === Status.Unprocessable) {
        setPaymentError(`There was an error processing your term balance payment.`);
        return;
      }
    }

    const response = await save({
      variables: {
        input,
        sourceID: paymentSourceID,
        skipBookableCheck: !!wonAuctionID || (IMPERSONATOR && !IMPERSONATOR.roles.includes(User__Role.L1Agent)),
      },
    });

    if (response?.data?.buildOrder?.status === Status.Ok) {
      const order = response?.data?.buildOrder.order;
      setOrderID(order!.id);
    } else {
      const errorMessage = response?.data?.buildOrder?.error ?? undefined;
      if (errorMessage?.includes('payment')) {
        setPaymentError(errorMessage);
      } else {
        setError(errorMessage);
        // Error alert currently is shown at top of page and therefore outside of the view of a user attempting to submit
        // Temp fix to move user to see alert
        window.scrollTo(0, 0);
      }
    }
  };

  const selectedIDs = new Set(items.map(({ id }) => id));
  const selectedInventory = inventoryData?.inventory.filter((item) => isSelected(item, selectedIDs));

  const loading = saving || charging || inventoryLoading;

  if (type === OrderTypeEnum.Pickup && serviceType === OrderServiceTypeEnum.Shipment && shippingOption) {
    return (
      <ConfirmInboundShipping
        orderID={orderID}
        address={address}
        contact={contact}
        disclaimers={disclaimersData?.disclaimersForBookingOrder}
        loading={loading}
        error={error}
        onSave={onSave}
        onPrev={onPrev}
        shippingOption={shippingOption}
        inboundShipments={inboundShipments}
      />
    );
  }

  if (orderID) {
    const url = serviceType === OrderServiceTypeEnum.Disposal ? orderURL(orderID) : ordersURL();
    return <Redirect push to={url} />;
  }

  if (
    type === OrderTypeEnum.Return &&
    serviceType === OrderServiceTypeEnum.Shipment &&
    shippingOption &&
    selectedInventory
  ) {
    return (
      <ConfirmOutboundShipping
        type={type}
        address={address}
        contact={contact}
        selectedItems={selectedInventory}
        disclaimers={disclaimersData?.disclaimersForBookingOrder}
        loading={loading}
        error={error}
        scheduled={scheduled}
        shippingOption={shippingOption}
        chargeTermCommitment={chargeTermCommitment}
        paymentError={paymentError}
        paymentSourceID={paymentSourceID}
        setPaymentSourceID={setPaymentSourceID}
        onSave={onSave}
        onPrev={onPrev}
      />
    );
  }

  if (serviceType === OrderServiceTypeEnum.Disposal && selectedInventory) {
    return (
      <Disposal
        selectedItems={selectedInventory}
        disclaimers={disclaimersData?.disclaimersForBookingOrder}
        loading={loading}
        error={error}
        scheduled={scheduled}
        setPaymentSourceID={setPaymentSourceID}
        chargeTermCommitment={chargeTermCommitment}
        paymentError={paymentError}
        paymentSourceID={paymentSourceID}
        waiveFees={waiveFees}
        setWaiveFees={setWaiveFees}
        waiveDocuments={waiveDocuments}
        setWaiveDocuments={setWaiveDocuments}
        onPrev={onPrev}
        onSave={onSave}
      />
    );
  }

  return (
    <Base>
      <Container>
        {error && <Alert style="danger">{error}</Alert>}
        <Text.Title size="large">Confirm your appointment info</Text.Title>
        <ServiceType />
        <Address address={address} />
        <Scheduling
          scheduled={scheduled}
          duration={duration}
          facilityWarehouse={facilityWarehouse}
          confirmedDate={confirmedDate}
        />
        {waitlistedDates.length > 0 && <Waitlist waitlistedDates={waitlistedDates}></Waitlist>}
        {contact && <Contact contact={contact} />}
        {hasPickup(type) && (
          <Section title="Items Entering Storage">
            <PickupInventory />
          </Section>
        )}
        {hasReturn(type) && selectedInventory && (
          <Section title="Items Leaving Storage">
            <ReturnInventory selections={selectedInventory} />
          </Section>
        )}
        <Disclaimers
          subtype={subtype}
          disclaimers={disclaimersData?.disclaimersForBookingOrder}
          extendedServiceArea={extendedServiceArea}
          longDistance={serviceType === OrderServiceTypeEnum.LongDistance}
        />
        {chargeTermCommitment && (
          <TermCommitmentPayment
            scheduled={scheduled}
            selectedSourceID={paymentSourceID}
            setSelectedSourceID={setPaymentSourceID}
            error={paymentError}
          />
        )}
        {wonAuctionID && (
          <PaymentMethodSelection
            prompt={''}
            selectedSourceID={paymentSourceID}
            setSelectedSourceID={setPaymentSourceID}
            error={paymentError}
          />
        )}
      </Container>
      {data && data.itemPricing ? (
        <PerItemPricingConfirmFooter
          loading={loading}
          onSave={onSave}
          onPrev={onPrev}
          pricingInfo={data.itemPricing}
          type={type}
          input={input}
        />
      ) : (
        <Button
          block
          size="lg"
          kind="primary"
          onClick={onSave}
          loading={loading}
          loadingLabel="Booking Appointment..."
          disabled={!!wonAuctionID && !paymentSourceID}
        >
          {confirmedDate ? 'Book Appointment' : 'Confirm Appointment Info'}
        </Button>
      )}
    </Base>
  );
};
