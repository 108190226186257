import styled from '@emotion/styled';
import React from 'react';

import { BookNewAppointment } from '@portal/components/new_appointment';
import {
  AccountDetailFragment,
  Account__State as AccountState,
  Maybe,
  OrderTypeEnum,
  UpcomingStorageOnboardingFragment,
} from '@portal/schema';
import { onboardingUpdatable } from '@portal/utils/order';
import { PageReview } from '@portal/components/helpers/page_review';

import { Redirect } from 'react-router-dom';
import { appointmentURL } from '@portal/config/routes';
import { CreditBalance } from './credit_balance';
import { FAQ } from './faq';
import { DoorToDoorSubscriptions } from './door_to_door_subscriptions';
import { Onboarded } from './onboarded';
import { ServicePlan } from './service_plan';
import { Subscriptions as SelfStorageSubscriptions } from './self_storage/subscriptions';
import { TermCommitment } from './term_commitment';

const Container = styled.div`
  max-width: 712px;
`;

const DoorToDoorContainer = styled.div`
  margin-bottom: 42px;
`;

export const View: React.FC<{
  onboarding?: Maybe<UpcomingStorageOnboardingFragment>;
  account: AccountDetailFragment;
}> = ({ onboarding, account }) => {
  const {
    contractStartDate,
    state,
    upcomingSubscriptionSet,
    termCommitment,
    onboardable,
    upcomingSelfStorageSubscriptions,
    rateGroup,
    onboardingCostDetails,
    subsequentPickupCostDetails,
    subsequentReturnCostDetails,
    finalOrderCostDetails,
    laborRate,
    perItemPricing,
    requestableItemsExist,
    unscheduledStorageTreasuresAuctionsWon,
  } = account;

  const hasSelfStorage = !!upcomingSelfStorageSubscriptions.length;
  const hasDoorToDoorStorage = !!upcomingSubscriptionSet.storageSubscriptions.length;

  const unscheduledAuctionWins = unscheduledStorageTreasuresAuctionsWon ?? [];
  const hasUnscheduledAuctionWin = unscheduledAuctionWins.length > 0;

  if (hasUnscheduledAuctionWin) {
    return <Redirect to={{ pathname: appointmentURL() }} />;
  }

  if (onboardable && !hasSelfStorage) {
    return <BookNewAppointment requestableItemsExist={requestableItemsExist} />;
  } else if (!hasSelfStorage && (state === AccountState.Canceled || !hasDoorToDoorStorage)) {
    return <Onboarded />;
  }

  const allowPlanUpdate = onboardingUpdatable(onboarding);
  const storeAndMoveCostDetails =
    onboarding?.parent?.type === OrderTypeEnum.Move && onboarding.parent.laborCostDetails
      ? onboarding.parent.laborCostDetails
      : undefined;

  return (
    <Container>
      {hasDoorToDoorStorage && state !== AccountState.Canceled && (
        <DoorToDoorContainer>
          <DoorToDoorSubscriptions
            subscriptionSet={upcomingSubscriptionSet}
            onboardingID={onboarding?.id}
            serviceType={rateGroup?.serviceType}
            allowPlanUpdate={allowPlanUpdate}
          />
          <CreditBalance />
          {!!termCommitment && (
            <TermCommitment
              term={termCommitment.term}
              contractStartDate={contractStartDate ?? undefined}
              onboardingID={onboarding?.id}
              allowPlanUpdate={allowPlanUpdate}
            />
          )}
          <ServicePlan
            onboardingCostDetails={onboardingCostDetails}
            finalOrderCostDetails={finalOrderCostDetails}
            storeAndMoveCostDetails={storeAndMoveCostDetails}
            onboarding={onboarding}
            allowPlanUpdate={allowPlanUpdate}
            subsequentPickupCostDetails={subsequentPickupCostDetails}
            subsequentReturnCostDetails={subsequentReturnCostDetails}
          />
          <FAQ
            planUpdatable={allowPlanUpdate}
            perItemPricing={perItemPricing}
            laborRate={laborRate}
            onboardingCostDetails={onboardingCostDetails}
            subsequentPickupCostDetails={subsequentPickupCostDetails}
            subsequentReturnCostDetails={subsequentReturnCostDetails}
            storeAndMoveCostDetails={storeAndMoveCostDetails}
          />
        </DoorToDoorContainer>
      )}
      {hasSelfStorage && <SelfStorageSubscriptions subscriptions={upcomingSelfStorageSubscriptions} />}
      <PageReview />
    </Container>
  );
};
