import { IWorkFlowStep } from './types';

export const AUCTION_PICKUP_WORKFLOW: IWorkFlowStep[] = [
  {
    name: 'new',
  },
  {
    name: 'facility_expectations',
  },
  {
    name: 'contact',
  },
  {
    name: 'date',
  },
  {
    name: 'time',
    skip: ({ intervalConfig, confirmedDate }) =>
      (!confirmedDate && !intervalConfig) || (!!intervalConfig && intervalConfig.forced),
  },
  {
    name: 'waitlist',
    skip: ({ scheduledDate, waitlistedDates }) => !scheduledDate || waitlistedDates.length === 0,
  },
  { name: 'confirm' },
];
