import { OrderServiceTypeEnum } from '@portal/schema';

import { ISkipProps } from './workflows/types';
import { STANDARD_WORKFLOW } from './workflows/standard';
import { SHIPPING_WORKFLOW } from './workflows/shipping';
import { FACILITY_WORKFLOW } from './workflows/facility';
import { AUCTION_PICKUP_WORKFLOW } from './workflows/auction_pickup';

function determineWorkflow(schedulingAuctionPickup: boolean, serviceType?: OrderServiceTypeEnum) {
  if (schedulingAuctionPickup) {
    return AUCTION_PICKUP_WORKFLOW;
  }

  switch (serviceType) {
    case OrderServiceTypeEnum.Shipment:
      return SHIPPING_WORKFLOW;
    case OrderServiceTypeEnum.Facility:
      return FACILITY_WORKFLOW;
    default:
      return STANDARD_WORKFLOW;
  }
}

export const next = (step: string, skipProps: ISkipProps): string => {
  const workflow = determineWorkflow(skipProps.schedulingAuctionPickup, skipProps.serviceType);
  const iterator = workflow[Symbol.iterator]();

  for (let result = iterator.next(); !result.done; result = iterator.next()) {
    if (result.value.name === step) {
      break;
    }
  }
  for (let result = iterator.next(); !result.done; result = iterator.next()) {
    if (!result.value.skip || !result.value.skip(skipProps)) {
      return result.value.name;
    }
  }
  throw new Error(`unable to advance for step ${step}`);
};
